<template>
  <!--  <div class="container">
    <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">--> 
      <!--row row-cols-1 row-cols-sm-2 row-cols-md-5-->
     <!--<div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
        <div class="col mb-3 d-flex flex-column align-middle">
          <h5 class="d-flex justify-content-center">Bundesländer</h5>
          <ul class="nav flex-column">
            <li
              class="nav-item mb-2 d-flex justify-content-center"
              v-for="bundesland in bundeslaender"
              :key="bundesland"
            >
              <a href="#" class="nav-link p-0">{{
                bundesland.name
              }}</a>
            </li>
          </ul>
        </div>

        <div class="col mb-3 d-flex flex-column align-middle">
          <h5 class="d-flex justify-content-center">Top Sehenswürdigkeiten:</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Der Stephansdom</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0"
                >Die Krimmler Wasserfälle</a
              >
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Der Wiener Prater</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0"
                >Das Goldene Dachl in Innsbruck</a
              >
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0"
                >Mozarts Geburtshaus in Salzburg</a
              >
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0"
                >Die Tscheppaschlucht in Kärnten</a
              >
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0"
                >Der Alpenzoo Innsbruck</a
              >
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0"
                >Die Festung Hohensalzburg</a
              >
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0"
                >Die Großglockner Hochalpenstraße</a
              >
            </li>
          </ul>
        </div>

        <div class="col mb-3 d-flex flex-column align-middle">
          <h5 class="d-flex justify-content-center">Section</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Home</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Features</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Pricing</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">FAQs</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">About</a>
            </li>
          </ul>
        </div>

        <div class="col mb-3 d-flex flex-column align-middle">
          <h5 class="d-flex justify-content-center">Section</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Home</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Features</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">Pricing</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">FAQs</a>
            </li>
            <li class="nav-item mb-2 d-flex justify-content-center">
              <a href="#" class="nav-link p-0">About</a>
            </li>
          </ul>
        </div>
      </div>-->
      <!--  <div class="row">
        <div class="col mb-3 d-flex flex-column align-middle">
          <a
            href="/"
            class="d-flex align-items-center mb-3 link-dark text-decoration-none"
          >
            <img
              width="32"
              height="32"
              src="/favicon_small.png"
              alt="/favicon.ico"
            />
            GemeindeKompass360
          </a>
          <p class="text-muted">
            © 2022-{{ new Date().getFullYear() }}, Alex Polan
          </p>
        </div>
      </div>-->
     <!--- <div
        class="d-flex flex-wrap justify-content-between align-items-center pt-4 pb-3 my-4 border-top"
      >
        <div class="col-md-4 d-flex align-items-center">
          <a
            href="/"
            class="me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
          >
            <svg class="bi" width="30" height="24">
              <use xlink:href="#bootstrap"></use>
            </svg>
          </a>
          <span class="mb-md-0 text-body-secondary">
            <a
              href="/"
              class="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img
                width="32"
                height="32"
                src="/favicon_small.png"
                alt="/favicon.ico"
              />
              GemeindeKompass360
            </a></span
          >
        </div>

        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li class="ms-3">
            <p class="text-muted">
              © 2022-{{ new Date().getFullYear() }}, Alex Polan
            </p>
          </li>
        </ul>
      </div>
    </footer>
  </div>-->

  <footer class="bd-footer py-4 py-md-5 mt-5 bg-dark text-white">
  <div class="container py-4 py-md-5 px-4 px-md-3 text-body-secondary">
    <div class="row border-bottom">
      <div class="col mb-3 d-flex flex-column align-middle">
        <h5 class="d-flex justify-content-center f-heading">Sehenswürdigkeiten</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Der Stephansdom</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Die Krimmler Wasserfälle</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Der Wiener Prater</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Das Goldene Dachl in Innsbruck</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Mozarts Geburtshaus in Salzburg</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Die Tscheppaschlucht in Kärnten</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Der Alpenzoo Innsbruck</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Die Festung Hohensalzburg</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Die Großglockner Hochalpenstraße</a>
          </li>
        </ul>
      </div>
      
      <div class="col mb-3 d-flex flex-column align-middle">
        <h5 class="d-flex justify-content-center f-heading">Section</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Home</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Features</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Pricing</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">FAQs</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">About</a>
          </li>
        </ul>
      </div>
      <div class="col mb-3 d-flex flex-column align-middle">
        <h5 class="d-flex justify-content-center f-heading">Section</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Home</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Features</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">Pricing</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">FAQs</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="#" class="nav-link p-0">About</a>
          </li>
        </ul>
      </div>
            <div class="col mb-3 d-flex flex-column align-middle">
        <h5 class="d-flex justify-content-center f-heading">Rechtliches</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="Datenschutz.html" class="nav-link p-0">Datenschutz</a>
          </li>
          <li class="nav-item mb-2 d-flex justify-content-center">
            <a href="Impressium.html" class="nav-link p-0">Impressium</a>
          </li>
        </ul>
      </div>
    </div>
    
    <div class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
      <div class="col-md-4 d-flex align-items-center">
        <a href="/" class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
          <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
        </a>
        <span class="mb-3 mb-md-0 text-body-secondary">© 2024 gk360.at</span>
      </div>
      <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li class="ms-3"><a class="text-body-secondary" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#twitter"></use></svg></a></li>
        <li class="ms-3"><a class="text-body-secondary" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#instagram"></use></svg></a></li>
        <li class="ms-3"><a class="text-body-secondary" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#facebook"></use></svg></a></li>
      </ul>
    </div>
  </div>
</footer>

</template>
<script>
export default {
  data() {
    return {
      bundeslaender: [
        {
          name: "Burgenland",
        },
        {
          name: "Kärnten",
        },
        {
          name: "Niederösterreich",
        },
        {
          name: "Oberösterreich",
        },
        {
          name: "Salzburg",
        },
        {
          name: "Steiermark",
        },
        {
          name: "Tirol",
        },
        {
          name: "Vorarlberg",
        },
        {
          name: "Wien",
        },
      ],
    };
  },
};
</script>
<style scoped>
footer {
  background-color: #383838 !important;
  color: white;
}

.f-heading, li>a {
  color: #DDDDDD !important;
}

.f-heading {
  font-weight: 800;
  font-size: 1.5rem;
}

li>a {
  font-size: 1rem;
}
</style>
